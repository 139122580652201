/* #@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&family=Zen+Kaku+Gothic+New:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&family=Zen+Kaku+Gothic+New:wght@300;400;500&display=swap');

html, .App {
  text-align: left;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 1.0rem;
}

.Header {
  font-size: 1.0rem;
  text-transform: uppercase;
  padding-left: 2.5rem;
}
.Home {
  padding-bottom: 16px;
}

.Projects__Filter {
  font-size: 1.0rem;

}
.Projects {
  padding-left: 1rem;
  padding-right: 1rem;
}
.Home, .Home p {

  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 1.1em;
}

.AppContent--dark {
  background-color: #101010 !important;
}
.Wow_Image {
  min-height: 600px;
  background-color: salmon;
}

/* .ProjectDetails {
  box-shadow:
  0 2.8px 2.2px rgba(255, 255, 255, 0.034),
  0 6.7px 5.3px rgba(255, 255, 255, 0.048),
  0 12.5px 10px rgba(255, 255, 255, 0.06),
  0 22.3px 17.9px rgba(255, 255, 255, 0.072),
  0 41.8px 33.4px rgba(255, 255, 255, 0.086),
  0 100px 80px rgba(255, 255, 255, 0.12)
} */

.AppContent {
  min-height: 100%;
}

html,
body,
#root,
.App {
  height: 100%;
  margin: 0px;
  padding: 0px;
  color:  #101010;
}

.Projects_For_Year_YearLabel span {
  border-bottom: 2px solid red;
}

.ProjectDetails__Intro {
  min-height: 300px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-dark {
  background-color: #101010 !important;
}



.ProjectCard__Title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.Header__Brand {
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0ch;
}


h1.Section__Title, .Home h1{
  position: relative;
  font-size: 1.7rem;
  color: #404040;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1.75rem;
  margin-top: 2rem;

}
h1.Section__Title::before,  .Home h1:before {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #303030;
}

.Projects a:hover {
  text-decoration: none;
}